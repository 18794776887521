import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Container, Row, Col, Form, Button } from "react-bootstrap"

// import fastForwardLogo from "../images/fast-forward.svg"

const IndexPage = () => (
  <Layout>
    <Seo title="Gender Affirming Voice Therapy Group Form" />

    <div className="masthead mb-3">
      <Container className="singleCol text-center">
        <h1>Gender Affirming Voice Therapy Group Form</h1>
      </Container>
    </div>
    
    <Container className="singleCol">
      <p>Available to current and prospective clients. Session topics will vary based on attendees and may include but are not limited to: conversation practice, specific pitch and/or resonance work, tension exercises, nonspeaking components of communication (e.g. intonation and/or body language), and vocal hygene education. </p>
      <p>A minimum of 4 people is required for a full 1 hour group session. If less than 4 people attend, group will still run but time and/or topics will be adjusted. Vibe Speech Therapy reserves all rights to adjust group sessions as needed.</p>
      <p>Cancellation policy:  Notice of cancellation must be provided 48 hours is advance for all sessions, otherwise the full fee will be charged. </p>
      <p>Group sessions are planned for the last Tuesday of the month at 6pm PST. </p>
      <p>Event Address: Virtual via Zoom. (links emailed the morning of). Contact us at (415) 505-7212 or <a href="mailto:eliana.riveraslp@gmail.com">eliana.riveraslp@gmail.com</a></p>
    <Form 
                        name="gender-affirming-form-v3"
                        method="post"
                        // action="/contact-success/"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit="submit"
                    >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="gender-affirming-form-v3" />
                    <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" />
                    </label>
                    </p>
                     


                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label for="firstName">First Name</Form.Label>
                                    <Form.Control 
                                        type="text" name="first-name" id="firstName" required
                                     />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                            <Form.Group>
                                    <Form.Label for="lastName">Last Name</Form.Label>
                                    <Form.Control 
                                    required
                                       type="text" name="last-name"
                                       id="lastName"
                                     />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <Form.Group>
                              <Form.Label for="email">Email address</Form.Label>
                              <Form.Control 
                                  required
                                  type="email" 
                                  name="email" 
                                  id="email"
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                          <Form.Group>
                            <Form.Label for="phone">Phone (Optional)</Form.Label>
                            <Form.Control 
                                
                                type="text" 
                                name="phone" 
                                id="phone"
                            />
                        </Form.Group>
                        
                          </Col>
                        </Row>
                        <fieldset>
                          <p className="form-label">Please review the following recommended resources for therapy.</p>

                          <div className="form-check">
                            <label>
                              <input className="form-check-input" name="recommended-resources" type="checkbox" value="hands-free" required />
                              Hands free device with large enough screen to view visual models and stimulus.
                            </label>
                          </div>

                          <div className="form-check">
                            <label>
                              <input className="form-check-input" name="recommended-resources" type="checkbox" value="strong-internet"  required />
                              Strong internet connection
                            </label>
                          </div>

                          <div className="form-check">
                            <label>
                              <input className="form-check-input" name="recommended-resources" type="checkbox" value="private-space"  required />
                              Private space with limited distractions
                            </label>
                          </div>
                        </fieldset>
                        <Form.Group>
                            <Form.Label for="proposed-topics">Proposed topics/questions</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                name="message"
                                rows="3"
                                id="proposed-topics"
                             />
                        </Form.Group>
                        <Button type="submit" variant="outline-dark" className="mt-1">Submit</Button>
                        </Form>
    </Container>
    
  </Layout>
)

export default IndexPage
